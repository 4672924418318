/* src/app/components/ehr/patients/components/precious-ai/precious-ai.component.css */
.ant-layout-header {
  font-weight: 600;
  font-size: 20px;
  background-color: #f4f4f6;
  background: #f4f4f6;
}
nz-layout {
  background: #f4f4f6;
}
nz-content {
  padding-left: 50px;
  padding-right: 50px;
}
::ng-deep .ant-tabs-tab-btn {
  color: black !important;
}
::ng-deep .ant-tabs-card > .ant-tabs-nav ::ng-deep .ant-tabs-tab {
  background: rgba(118, 195, 188, 0.5);
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px 10px 0px 0px !important;
  font-weight: 500;
}
::ng-deep .ant-tabs-card > .ant-tabs-nav ::ng-deep .ant-tabs-tab-active {
  background: white;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px 10px 0px 0px !important;
  font-weight: 500;
}
::ng-deep .ant-tabs-tab.ant-tabs-tab-active ::ng-deep .ant-tabs-tab-btn {
  color: black !important;
}
::ng-deep .ant-tabs-tab {
  min-width: 200px;
  margin-right: 3px !important;
}
::ng-deep .ant-tabs {
  line-height: 2;
  min-height: 70vh;
}
::ng-deep .ant-tabs-top > .ant-tabs-nav {
  margin: 0px !important;
}
::ng-deep .ant-tabs-content-holder {
  height: 100%;
  padding: 2em 1em;
  border: 1px solid black;
  background: #f4f4f6;
}
/*# sourceMappingURL=precious-ai.component-TX422A2L.css.map */
